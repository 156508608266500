import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Right Start Reviews | Full-Service Mortgage Lender"
        description="Read reviews from happy homeowners we've worked with at Right Start Mortgage. There's nothing we love more than hearing from our clients!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative overflow-hidden py-16 md:py-20">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/reviews/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[640px] text-center">
            <h1 className="text-white">What Happy Homeowners Are Saying</h1>
            <p className="text-white">
              There’s nothing we love more than hearing from our clients.
              Helping you reach your goals and realize your dreams is what we’re
              all about, and your words mean so much to us.
            </p>

            <ButtonSolid href="/review-us/" text="Leave a Review" />
          </div>
        </div>
      </section>

      <section className="bg-primary-50 pt-16 pb-12 md:pt-20 md:pb-16">
        <div className="container">
          <div className="mb-16 md:mb-22 md:columns-2 md:gap-x-12">
            <ReviewCards />
          </div>
        </div>

        <CallToAction className="!mb-0" />
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
